@import '../variables';
@import '../mixin';

main.page-content.vendor2 {
    .hero_in {
        &.detail_page {
            overflow: initial;
        }
    }

    .logo {
        width: 200px;
        height: 200px;
        background-color: #fff;
        position: relative;
        left: -100px;
        top: 80px;
        z-index: 5;
        border: solid 3px #ddd;
        border-radius: 8px;
    }

    .main_info {
        &>.row {
            margin-right: 0px;
            padding-left: 15px;
        }
    }

    #sidebar_fixed {
        margin-top: 60px;

        h3 {
            font-size: 20px;
        }

        .hours-schema {
            margin: 0px;
        }
    }

    .other_info {
        background-color: transparent !important;
        border-radius: 5px;
    }

    .tabs_detail {
        &.main {
            margin-top: 50px;
        }
    }

    .body {
        .description {
            padding: 18px 32px;
            border: dotted 2px #e5e5e5;
            margin-top: 60px;
            border-radius: 6px;
            font-size: 16px;
            font-weight: 500;
            line-height: 26px;
            @include box-shadow (0px 0px 15px 0px rgba(0, 0, 0, 0.12));
        }
    }

    .box_booking {
        border: 2px dotted #d2d8dd;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        border-radius: 5px;
        margin-bottom: 25px;
        background-color: #fff;
    }
    .box_booking .head {
      text-align: center;
      padding: 23px 20px 18px 20px;
      background-color: #f8f8f8;
      border-bottom: 1px dotted #d2d8dd;
    }
    .box_booking .head h3 {
        font-size: 24px;
        font-size: 1.5rem;
        margin: 0;
    }
    .box_booking .head small {
        display: block;
    }
    .box_booking .head .offer {
        color: #e74747;
        font-weight: 500;
        font-size: 16px;
        font-size: 1rem;
    }
    .box_booking .main {
        padding: 25px;
    }
    .box_booking .main ul {
        list-style: none;
        padding: 0;
    }
    .box_booking .main ul li {
        margin-bottom: 8px;
    }
    .box_booking .main ul li.total {
        font-size: 18px;
        font-size: 1.125rem;
        font-weight: 600;
        color: #e74747;
        text-transform: uppercase;
    }
    .box_booking .main ul li a {
        padding-left: 30px;
        color: #444;
        display: inline-block;
        position: relative;
    }
    .box_booking .main ul li a:before {
        font-family: ElegantIcons;
        content: "\4f";
        font-size: 21px;
        font-size: 1.3125rem;
        position: absolute;
        left: 0;
        top: -2px;
        line-height: 1;
    }
    .box_booking .main ul li a:hover {
        color: #e74747;
    }
    .box_booking .main ul li span {
        float: right;
        font-weight: 500;
    }
    .box_booking .form-group {
        margin: 0 0 10px 0;
    }
    .box_booking textarea {
        height: 130px;
    }
    .opt_order {
        border-top: 1px solid #ededed;
        border-bottom: 1px solid #ededed;
        margin-bottom: 20px;
        padding: 15px 0 5px 0;
    }
    .tabs_detail {
        margin-top: 15px;
    }
    .tabs_detail .nav-tabs {
        border: none;
        border-bottom: 2px solid #ededed;
        display: flex;
    }
    @media (max-width: 767px) {
        .tabs_detail .nav-tabs {
            display: none;
        }
    }
    .tabs_detail .nav-tabs .nav-item {
        text-transform: uppercase;
        font-weight: 500;
    }
    .tabs_detail .tab-content .card {
        border: none;
        background-color: transparent;
    }
    .tabs_detail .tab-content .card .card-header {
        padding: 0;
        background-color: transparent;
        border: 0;
        background-color: #f8f8f8;
    }
    .tabs_detail .tab-content .card .card-header a {
        display: block;
        font-size: 18px;
        font-size: 1.125rem;
        padding: 15px;
        color: #222;
    }
    .tabs_detail .tab-content .card .card-body {
        padding: 45px 0 25px 0;
    }
    @media (max-width: 991px) {
        .tabs_detail .tab-content .card .card-body {
            padding: 25px 0 5px 0;
        }
    }
    @media (max-width: 991px) {
        .tabs_detail .tab-content .card .card-body.reviews .add_bottom_45 {
            padding-bottom: 25px;
        }
    }
    @media (min-width: 768px) {
        .tabs_detail .tab-content .card {
            border: none;
        }
        .tabs_detail .tab-content .card .card-header {
            display: none;
        }
        .tabs_detail .tab-content .card .collapse {
            display: block;
        }
    }
    @media (max-width: 767px) {
        .tabs_detail .tab-content {
          margin-bottom: 25px;
        }
        .tabs_detail .tab-content .card-header a:after {
          font-family: ElegantIcons;
          width: 20px;
          display: block;
          float: right;
        }
        .tabs_detail .tab-content .card-header a.collapsed:after {
          content: "\50";
        }
        .tabs_detail .tab-content .card-header a:after {
          content: "\4f";
        }
        .tabs_detail .tab-content .fade:not(.show) {
          opacity: 1;
        }
        .tabs_detail .tab-content .tab-pane {
          display: block !important;
          opacity: 1;
        }
    }
    .table.cart-list {
        margin-bottom: 30px;
        border-bottom: 1px solid #ededed;
    }
    .table.cart-list.table-striped tbody tr:nth-of-type(odd) {
        background-color: #f8f8f8;
    }
    .table.cart-list thead th {
        border: none;
        font-weight: 500;
    }
    .table.cart-list td {
        vertical-align: middle;
    }
    .table.cart-list td.options i {
        font-size: 26px;
    }
    .table.cart-list td h4 {
        margin: 0;
        font-size: 16px;
        font-size: 1rem;
    }
    .table.cart-list td p {
        margin: 0;
        font-size: 13px;
        font-size: 0.8125rem;
        color: #444;
    }
    .table.cart-list td figure {
        width: 60px;
        height: 60px;
        overflow: hidden;
        display: inline-block;
        float: left;
        margin: 0;
        margin-right: 15px;
        border: 1px solid #fff;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        border-radius: 3px;
    }
    .table.cart-list td figure img {
      width: 60px;
      height: auto;
    }
    @media (max-width: 767px) {
      .table.cart-list td figure {
        float: none;
      }
    }
    .table.cart-list td .dropdown-options {
        border: none;
        margin: 0;
    }
    .table.cart-list td .dropdown-options > a {
        padding: 0;
        display: inline-block;
        color: #589442;
    }
    .table.cart-list td .dropdown-options > a:after {
        display: none;
    }
    .table.cart-list td .dropdown-options > a:hover {
        color: #111;
    }
    .table.cart-list td .dropdown-menu {
        background-color: #fff;
        min-width: 250px;
        margin-left: -110px !important;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        border-radius: 3px;
    }
    @media (max-width: 991px) {
        .table.cart-list td .dropdown-menu {
          margin-left: -175px !important;
        }
    }
    .table.cart-list td .dropdown-menu label {
        font-weight: 400;
    }
    .table.cart-list td .dropdown-menu a {
        color: #fff;
    }
    .table.cart-list td .dropdown-menu h5 {
        border-bottom: 1px solid #ededed;
        padding-bottom: 10px;
        margin-bottom: 10px !important;
        font-size: 14px;
        font-size: 0.875rem;
    }
    .table.cart-list td .dropdown-menu ul {
        list-style: none;
        padding: 0;
    }
    .table.cart-list td .dropdown-menu ul li label small {
        float: right;
        padding-top: 3px;
    }
    #message {
        position: fixed;
        bottom: 15px;
        left: 15px;
        background-color: #589442;
        color: #fff;
        padding: 12px 12px 10px 40px;
        font-weight: 500;
        line-height: 1;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        border-radius: 5px;
        -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
        display: none;
    }
    #message:before {
        font-family: ElegantIcons;
        content: "\52";
        font-size: 21px;
        font-size: 1.3125rem;
        position: absolute;
        left: 10px;
        top: 7px;
        line-height: 1;
    }
    ul.share-buttons {
        padding: 0;
        list-style: none;
        text-align: center;
        margin: 0 0 25px 0;
    }
    ul.share-buttons li {
        display: inline-block;
        margin: 0 5px 5px 5px;
    }
    ul.share-buttons li a {
        background: #fff;
        border: 1px solid #ededed;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        border-radius: 3px;
        font-weight: 500;
        font-size: 13px;
        font-size: 0.8125rem;
        padding: 7px 20px;
        transition: 0.3s;
        display: inline-block;
        line-height: 17px;
        font-weight: 500;
    }
    ul.share-buttons li a:hover {
        color: #fff;
    }
    ul.share-buttons li a.fb-share {
        color: #3b5998;
    }
    ul.share-buttons li a.fb-share:hover {
        background: #3b5998;
        color: #fff;
        border-color: #3b5998;
    }
    ul.share-buttons li a.gplus-share {
        color: #dd4b39;
    }
    ul.share-buttons li a.gplus-share:hover {
        background: #dd4b39;
        color: #fff;
        border-color: #dd4b39;
    }
    ul.share-buttons li a.twitter-share {
        color: #1da1f2;
    }
    ul.share-buttons li a.twitter-share:hover {
        background: #1da1f2;
        color: #fff;
        border-color: #1da1f2;
    }
    ul.share-buttons li i {
        font-size: 16px;
        font-size: 1rem;
        position: relative;
        right: 3px;
        top: 2px;
    }
    .info_content h2 {
        text-align: center;
        font-weight: 500;
        margin-bottom: 20px;
        font-size: 24px;
        font-size: 1.5rem;
    }
    .info_content h3 {
        font-weight: 500;
        margin-bottom: 15px;
        font-size: 18px;
        font-size: 1.125rem;
    }
    .info_content hr {
        margin-top: 25px;
        margin-bottom: 25px;
    }
    .info_content .menu_item h4 {
        font-weight: 500;
        margin-bottom: 0;
        font-size: 14px;
        font-size: 0.875rem;
    }
    .info_content .menu_item em {
        float: right;
    }
    .info_content .menu_item p {
        color: #777;
    }
    .pictures {
        margin-bottom: 45px;
        text-align: center;
    }
    .pictures figure {
        margin: 0;
        overflow: hidden;
        position: relative;
        height: 120px;
        width: 120px;
        display: inline-block;
    }
    @media (max-width: 767px) {
        .pictures figure {
          width: 80px;
          height: 80px;
        }
    }
    .pictures figure a {
       display: block;
    }
    .pictures figure a span {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.7);
        color: #fff;
        font-size: 26px;
        font-size: 1.625rem;
    }
    .pictures figure a img {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%) scale(1);
        -moz-transform: translate(-50%, -50%) scale(1);
        -ms-transform: translate(-50%, -50%) scale(1);
        -o-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        -o-backface-visibility: hidden;
        backface-visibility: hidden;
        width: 100%;
        z-index: 1;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    .pictures figure a:hover img {
        -webkit-transform: translate(-50%, -50%) scale(1.05);
        -moz-transform: translate(-50%, -50%) scale(1.05);
        -ms-transform: translate(-50%, -50%) scale(1.05);
        -o-transform: translate(-50%, -50%) scale(1.05);
        transform: translate(-50%, -50%) scale(1.05);
    }
    a.show_hide:focus {
        color: #589442;
    }
    a.show_hide:focus:hover {
        color: #111;
    }
    .special_offers h2 {
        color: #e74747;
    }
    .special_offers h2:after,
    .special_offers h2:before {
        display: inline-block;
        width: 50px;
        height: 1px;
        content: "";
        vertical-align: middle;
        background: #e74747;
        margin: 0 8px;
    }
    .reviews #review_summary {
        text-align: center;
        background-color: #1f2f6a;
        color: #fff;
        padding: 20px 10px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        border-radius: 5px;
    }
    @media (max-width: 991px) {
        .reviews #review_summary {
          margin-bottom: 15px;
        }
    }
    .reviews #review_summary strong {
        font-size: 42px;
        font-size: 2.625rem;
        display: block;
        line-height: 1;
    }
    .reviews #review_summary em {
        font-style: normal;
        font-weight: 500;
        display: block;
    }
    .reviews .progress {
        margin-bottom: 12px;
        height: 5px;
    }
    .reviews .progress-bar {
        background-color: #589442;
    }
    .reviews .reviews_sum_details h6 {
        font-size: 14px;
        font-size: 0.875rem;
    }
    .reviews .reviews_sum_details strong {
        position: relative;
        top: -8px;
    }
    .review_card {
        border: 1px solid #ededed;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        border-radius: 3px;
        padding: 25px 25px 10px 25px;
        margin-bottom: 30px;
        -webkit-box-shadow: 0 2px 4px rgba(3, 27, 78, 0.06);
        -moz-box-shadow: 0 2px 4px rgba(3, 27, 78, 0.06);
        box-shadow: 0 2px 4px rgba(3, 27, 78, 0.06);
    }
    .review_card .user_info {
        text-align: center;
    }
    @media (max-width: 767px) {
        .review_card .user_info {
          margin-bottom: 10px;
        }
    }
    .review_card .user_info figure {
        width: 50px;
        height: 50px;
        overflow: hidden;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        margin-bottom: 10px;
    }
    @media (max-width: 767px) {
        .review_card .user_info figure {
          float: left;
        }
    }
    .review_card .user_info figure img {
        width: 50px;
        height: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    .review_card .user_info h5 {
        font-size: 13px;
        font-size: 0.8125rem;
    }
    @media (max-width: 767px) {
        .review_card .user_info h5 {
          float: left;
          margin: 18px 0 0 15px;
        }
    }
    .review_card .review_content h4 {
        font-size: 18px;
        font-size: 1.125rem;
    }
    .review_card .review_content .rating {
        float: left;
        font-weight: 600;
        font-size: 26px;
        font-size: 1.625rem;
        color: #589442;
    }
    .review_card .review_content .rating small {
        font-size: 12px;
        font-size: 0.75rem;
        font-weight: 600;
    }
    .review_card .review_content .rating strong {
        font-size: 13px;
        font-size: 0.8125rem;
        font-weight: 400;
        color: #444;
    }
    @media (max-width: 575px) {
        .review_card .review_content .rating {
          float: none;
          display: block;
        }
    }
    @media (max-width: 575px) {
        .review_card .review_content .rating em {
          float: none;
          display: inline-block;
          margin-left: 10px;
        }
    }
    .review_card .review_content em {
        color: #999;
        float: right;
    }
    @media (max-width: 575px) {
        .review_card .review_content em {
            float: none;
            display: block;
            margin-top: 10px;
            margin-left: 0;
        }
    }
    .review_card .review_content ul {
        margin: 35px 0 25px 0;
        padding: 0;
    }
    .review_card .review_content ul li {
        display: inline-block;
        font-size: 13px;
        font-size: 0.8125rem;
        margin-right: 0;
    }
    @media (max-width: 575px) {
        .review_card .review_content ul li span {
            display: none;
        }
    }
    .review_card .review_content ul li a {
        background-color: #f0f0f0;
        padding: 7px 10px 5px 10px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        border-radius: 3px;
        color: #444;
    }
    .review_card .review_content ul li a:hover {
        background-color: #589442;
        color: #fff;
    }
    .review_card .review_content ul li a i {
        margin-right: 5px;
    }
    @media (max-width: 575px) {
        .review_card .review_content ul li a i {
          margin-right: 0;
        }
    }
    .review_card .review_content ul li:last-child {
        float: right;
        margin-right: 0;
    }
    .review_card .reply {
        padding-top: 30px;
    }
    @media (max-width: 767px) {
        .review_card .reply .user_info {
          display: none;
        }
    }
    .review_card .reply .user_info figure {
        width: 50px;
        height: 50px;
        overflow: hidden;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        margin-bottom: 10px;
    }
    .review_card .reply .user_info figure img {
        width: 50px;
        height: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    .review_card .reply .user_info h5 {
        font-size: 14px;
        font-size: 0.875rem;
    }
    .review_card .reply .review_content {
        border-left: 5px solid #ededed;
        padding-left: 20px;
    }
    @media (max-width: 575px) {
        .review_card .reply .review_content strong {
          display: block;
        }
    }
    .review_card .reply .review_content em {
        color: #999;
        font-style: italic;
        float: right;
    }
    @media (max-width: 575px) {
        .review_card .reply .review_content em {
          float: none;
          display: block;
          margin-top: 10px;
        }
    }
    .dropdown {
        border: 1px dotted #c8c8c8;
        padding: 0;
        margin-bottom: 5px;
    }
    .dropdown a {
        padding: 15px 15px 12px 15px;
        line-height: 1;
        color: #444;
        font-weight: 500;
        display: block;
        position: relative;
    }
    .dropdown a:after {
        font-family: food;
        font-size: 21px;
        font-size: 1.3125rem;
        font-weight: 700;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        position: absolute;
        right: 10px;
        top: 10px;
    }
    .dropdown.show a {
        color: #333;
    }
    .dropdown.show a:after {
        transform: rotateY(360deg);
        color: #333;
    }
    .dropdown .dropdown-menu {
        font-size: 14px;
        font-size: 0.875rem;
        background: #f8f8f8;
        border: 1px solid #ddd;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        border-radius: 0;
        padding: 15px;
        width: 100.5%;
        left: -1px !important;
        right: -1px !important;
        margin: 5px 0 0 0;
    }
    .dropdown .dropdown-menu h4 {
        text-align: center;
        font-size: 14px;
        font-size: 0.875rem;
        text-transform: uppercase;
    }
    .dropdown.time a:after {
        content: "\0057";
    }
    .dropdown.time {
        margin-bottom: 25px;
    }
    .dropdown.day a:after {
        font-family: ElegantIcons;
        content: "\e023";
        font-weight: 300;
    }
    .radio_select ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
        text-align: center;
    }
    .radio_select ul li {
        display: inline-block;
        margin: 0;
        width: 23.5%;
    }
    .radio_select.chose_day ul li {
        width: 43.5%;
    }
    .radio_select input[type="radio"] {
        opacity: 0;
        position: fixed;
        width: 0;
    }
    .radio_select label {
        display: inline-block;
        text-align: center;
        width: 100%;
        padding: 8px;
        border: 1px dotted #c8c8c8;
        background-color: #fff;
        font-size: 16px;
        font-size: 1rem;
    }
    .radio_select label em {
        display: block;
        color: red;
        font-style: normal;
        font-size: 11px;
        font-size: 0.6875rem;
        font-weight: 500;
    }
    .radio_select label:hover {
        background-color: #dfd;
    }
    .radio_select input[type="radio"]:focus + label {
        border: 1px solid transparent;
        background-color: #589442;
        color: #fff;
    }
    .radio_select input[type="radio"]:focus + label em {
        color: #ff6;
    }
    .radio_select input[type="radio"]:checked + label {
        border: 1px solid transparent;
        background-color: #589442;
        color: #fff;
    }
    .radio_select input[type="radio"]:checked + label em {
        color: #ff6;
    }
    .other_info {
        background-color: #f9f9f9;
        padding: 25px 25px 5px 25px;
        margin-bottom: 20px;
    }
    .other_info h2 {
        margin-bottom: 35px;
    }
    .follow_us_detail a {
        color: #999;
        display: inline-block;
        margin: 5px 5px 0 0;
    }
    .follow_us_detail a:hover {
        color: #589442;
    }
    .follow_us_detail a i {
        font-size: 24px;
        font-size: 1.5rem;
    }


    @media (max-width: $medium-device-width) {
        .main_info {
            text-align: center;

            .description {
                position: relative;
                top: 40px;
            }
        }

        .logo {
            left: 0px;
        }
    }

    @media (min-width: $large-device-width) {
		.product-card {
			&:not(:hover) {
				picture {
					img {
						filter: grayscale(50%);
					}
				}

				.actions {
					.not-added-container {
						button {
							background-color: transparent;
							border-color: transparent;
							color: $color-text;
						}
					}
				}
			}
		}
	}
}